<template>
  <v-app id="auth-template" app>
    <v-main class="pt-5">
      <header>
        <img src="~@/assets/img/logo.png" alt="World Bowls Match Center" />
        <h1>Match Center</h1>
        <h2>Digital Scoring</h2>
      </header>
      <router-view />

      <div class="settings-link" @click="enableSettings"></div>
      <v-bottom-sheet v-model="showSettings">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" color="primary" text v-on="on"
            ><v-icon>{{ icon }}</v-icon
            >Settings</v-btn
          >
        </template>
        <v-sheet>
          <settings-form />
        </v-sheet>
      </v-bottom-sheet>
    </v-main>
  </v-app>
</template>

<script>
import { mdiCog } from "@mdi/js";
import SettingsForm from "@/components/SettingsForm";
export default {
  components: { SettingsForm },
  data() {
    return {
      icon: mdiCog,
      showSettings: false,
    };
  },
  methods: {
    enableSettings() {
      this.showSettings = true;
    },
  },
};
</script>

<style scoped lang="scss">
#auth-template {
  height: 100%;
  background-image: url("~@/assets/img/login-bg.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 250px;
  text-align: center;

  header {
    text-transform: uppercase;
    h1 {
      color: #b1953b;
      font-weight: 600;
    }
    h2 {
      font-weight: normal;
    }
  }
  .settings-link {
    margin-top: 10px;
    color: var(--color-primary);
    cursor: pointer;
    * {
      color: var(--color-primary);
    }
  }
}
</style>
